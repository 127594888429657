import React, { useEffect, useState } from "react";
import { addlist, month_short } from "../../API/api";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";

import dayjs from "dayjs";

export const AddiTems = () => {
  const [addItem_modal, setaddItem_modal] = useState(false);
  const [save_btn, setsave_btn] = useState(false);

  const [page_values, setpage_values] = useState({
    start_date: null,
    // start_date:
    // {
    //   $D: 29,
    //   $H: 0,
    //   $L: "en",
    //   $M: 2,
    //   $W: 3,
    //   $d: "Wed Mar 29 2023 00:00:00 GMT+0530 (India Standard Time) {}",
    //   $m: 0,
    //   $ms: 0,
    //   $s: 0,
    //   $u: undefined,
    //   $x: {},
    //   $y: 2023,
    // },
    // dayjs("2023-03-28T18:30:00.000Z"),
    start_time: null,
    // end_date: null,
    end_time: null,
    add: "",
    apt: "",
  });

  useEffect(() => {
    if (
      page_values.start_date?.$d &&
      // page_values.end_date?.$d &&
      new Date(page_values.start_date?.$d) != "Invalid Date" &&
      // new Date(page_values.end_date?.$d) != "Invalid Date" &&
      page_values.start_time?.$d &&
      page_values.end_time?.$d &&
      new Date(page_values.start_time?.$d) != "Invalid Date" &&
      new Date(page_values.end_time?.$d) != "Invalid Date" &&
      page_values.add !== "" &&
      page_values.apt !== ""
    ) {
      setsave_btn(true);
    } else {
      setsave_btn(false);
    }
  }, [page_values]);
  console.log(page_values);
  const handel_pagevalues = (data) => {
    return setpage_values((prev) => ({ ...prev, ...data }));
  };
  console.log(page_values);
  const handelSaveItem = () => {
    if (!save_btn) {
      return;
    }
    let data_obj = {
      add: page_values.add,
      apt: page_values.apt,
      start: JSON.stringify({
        date:
          month_short[page_values.start_date.$M] +
          " " +
          ("00" + page_values.start_date.$D).slice(-2) +
          " " +
          page_values.start_date.$y,
        time:
          ("00" + page_values.start_time.$H).slice(-2) +
          ":" +
          ("00" + page_values.start_time.$m).slice(-2),
      }),
      end: JSON.stringify({
        date:
          month_short[page_values.start_date.$M] +
          " " +
          ("00" + page_values.start_date.$D).slice(-2) +
          " " +
          page_values.start_date.$y,
        time:
          ("00" + page_values.end_time.$H).slice(-2) +
          ":" +
          ("00" + page_values.end_time.$m).slice(-2),
      }),
    };
    // console.log(data_obj);
    // return;
    addlist(
      {
        ...data_obj,
      },
      (res) => {
        if (!res.status) {
          alert("Network error Check connetion or try again");
          return;
        }
        return window.location.reload();
      }
    );
  };
  return (
    <>
      <div className="add_items_modal" active={addItem_modal.toString()}>
        <div className="modal">
          <div className="modal_header">
            <div className="close"></div>
            <div className="txt">New Entry</div>
            <div className="close">
              <div></div>
              <div></div>
            </div>
          </div>
          <div>
            <TextField
              id="outlined-multiline-flexible"
              label="Address"
              multiline
              rowsMax="3"
              value={page_values.add}
              onChange={({ target }) => {
                handel_pagevalues({ add: target.value });
              }}
              margin="normal"
              variant="outlined"
              size="normal"
            />
          </div>
          <div>
            <TextField
              id="outlined-basic"
              label="Apartment"
              variant="outlined"
              value={page_values.apt}
              onChange={({ target }) => {
                handel_pagevalues({ apt: target.value });
              }}
            />
          </div>
          <div className="">
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of cleaning"
                  value={page_values.start_date}
                  onChange={(newValue) => {
                    handel_pagevalues({ start_date: newValue });
                  }}
                  format="MM/DD/YYYY"
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="time_group">
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Start Time"
                  value={page_values.start_time}
                  onChange={(newValue) =>
                    handel_pagevalues({ start_time: newValue })
                  }
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="End Time"
                  value={page_values.end_time}
                  onChange={(newValue) =>
                    handel_pagevalues({ end_time: newValue })
                  }
                />
              </LocalizationProvider>
            </div>
          </div>
          {/* <div className="time_group">
            <div className="title">End</div>
            <div>
              <div className="title">Date</div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={page_values.end_date}
                  onChange={(newValue) =>
                    handel_pagevalues({ end_date: newValue })
                  }
                  format="MMM DD, YYYY"
                />
              </LocalizationProvider>
            </div>
            <div>
              <div className="title">Time</div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  value={page_values.end_time}
                  onChange={(newValue) =>
                    handel_pagevalues({ end_time: newValue })
                  }
                />
              </LocalizationProvider>
            </div>
          </div> */}

          <div className="btn_group">
            <div
              className="btn cancel"
              onClick={() => {
                setaddItem_modal(false);
                setpage_values({
                  start_date: null,
                  start_time: null,
                  // end_date: null,
                  end_time: null,
                  add: "",
                  apt: "",
                });
              }}
            >
              Cancel
            </div>
            <div
              className="btn save"
              active={save_btn.toString()}
              onClick={handelSaveItem}
            >
              Save
            </div>
          </div>
        </div>
      </div>
      <div className="add_plus" onClick={() => setaddItem_modal(true)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xlink="http://www.w3.org/1999/xlink"
          svgjs="http://svgjs.com/svgjs"
          width="20"
          height="20"
          x="0"
          y="0"
          viewBox="0 0 512 512"
          space="preserve"
          class=""
        >
          <g>
            <path
              d="M256 439.98c-13.81 0-25-11.19-25-25V97.02c0-13.81 11.19-25 25-25s25 11.19 25 25v317.96c0 13.81-11.19 25-25 25z"
              fill="var(--base)"
            ></path>
            <path
              d="M414.98 281H97.02c-13.81 0-25-11.19-25-25s11.19-25 25-25h317.96c13.81 0 25 11.19 25 25s-11.19 25-25 25z"
              fill="var(--base)"
            ></path>
          </g>
        </svg>

        <div>Add</div>
      </div>
    </>
  );
};
