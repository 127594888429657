import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { color_obj, itemdelete } from "../../API/api";
import { EditiTems } from "../EditItems/EditiTems";

export const Table = ({
  table,
  current_selectedItem,
  setcurrent_selectedItem,
  setviewItem_modal,
  setmodal_type,
  handel_modaldata,
  setloader_acive,
}) => {
  const [menu_active, setmenu_active] = useState("");
  useEffect(() => {
    document.body.addEventListener("click", () => {
      setmenu_active("");
    });
  }, []);

  return (
    <div className="table">
      {table.length === 0 ? (
        "No data found"
      ) : (
        <>
          <div className="table_header">
            {["Address", "Apt", "Date and Time", "Status", "Action"].map(
              (el) => (
                <div key={el}>{el}</div>
              )
            )}
          </div>
          <div className="table_body">
            {table.map((el, i) => {
              let [start, end] = [JSON.parse(el.start), JSON.parse(el.end)];
              return (
                <div
                  key={i}
                  className={
                    "table_row" +
                    (current_selectedItem?.id === el.id ? " active" : "")
                  }
                  onClick={() => {
                    handel_modaldata({ ...el });
                    // setcurrent_selectedItem(el);
                    setmodal_type("fulledit");
                  }}
                >
                  <div className="address">{el.add}</div>
                  <div>{el.apt}</div>
                  <div>
                    <div>
                      {[start.date].reduce((prev, curr) => {
                        let temp_arr = curr.split(" ");
                        return `${temp_arr[0]} ${temp_arr[1]}, ${temp_arr[2]}`;
                      }, "") +
                        " " +
                        start.time +
                        " | " +
                        end.time}
                    </div>
                  </div>
                  <div
                    className="status"
                    style={{
                      "--colorToShow": color_obj[el.status] || "black",
                    }}
                  >
                    {el?.status ? <>{el.status}</> : "Err"}
                  </div>
                  <div className="menu_main">
                    <div
                      className="menu"
                      onClick={(e) => {
                        // setcurrent_selectedItem(el);
                        // setviewItem_modal(true);
                        setmenu_active(el.id);
                        e.stopPropagation();
                      }}
                    >
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div
                        className={
                          "menu_box" + (menu_active === el.id ? " active" : "")
                        }
                      >
                        <div
                          className="items"
                          onClick={(e) => {
                            // setcurrent_selectedItem({ ...el });
                            handel_modaldata({ ...el });
                            setmodal_type("edit");
                            e.stopPropagation();
                          }}
                        >
                          <div>Edit</div>
                        </div>
                        <div
                          className="items"
                          onClick={() => {
                            itemdelete(
                              {
                                id: el.id,
                              },
                              (data) => {
                                setloader_acive(false);
                                // console.log(data);
                                if (!data.status) {
                                  alert("Network error");
                                  return;
                                }
                                setmodal_type((prev) =>
                                  prev == "reload" ? "" : "reload"
                                );
                              }
                            );
                          }}
                        >
                          <div>Delete</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      {/* <EditiTems /> */}
    </div>
  );
};
