import axios from "axios";

const base_url = "https://housekeeping-api.montereyvalet.com/api/v1/mv";
// const base_url = "http://localhost:3050/api/v1/mv";

export const month_short = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
export let color_obj = {
  new: "var(--base)",
  cancel: "#EC541E",
  inprogress: "#FABF10",
  completed: "#79C5CA",
};

const api = {
  getitems: "/get-items",
  additem: "/add-item",
  itemdelete: "/item-delete",
  edititemimages: "/edit-item-photos",
  edititemstatus: "/edit-item-status",
  edititem: "/edit-item",
  getlink: "/get-links",
};
export const getlist = (callback) => {
  axios
    .post(`${base_url}${api.getitems}`, {}, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        data: response,
      });
    });
};
export const addlist = (obj, callback) => {
  axios
    .post(`${base_url}${api.additem}`, { ...obj }, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        data: response,
      });
    });
};
export const itemdelete = (obj, callback) => {
  // api not completed
  // return;
  axios
    .post(`${base_url}${api.itemdelete}`, { ...obj }, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        data: response,
      });
    });
};
export const edititemimages = (obj, callback) => {
  // api not completed
  // return;
  axios
    .post(`${base_url}${api.edititemimages}`, { ...obj }, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        data: response,
      });
    });
};
export const edititemstatus = (obj, callback) => {
  // api not completed
  // return;
  axios
    .post(`${base_url}${api.edititemstatus}`, { ...obj }, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        data: response,
      });
    });
};
export const edititem = (obj, callback) => {
  axios
    .post(`${base_url}${api.edititem}`, { ...obj }, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        data: response,
      });
    });
};
export const getlink = (obj, callback) => {
  if (
    obj.base_64.split("julietteimages.s3.amazonaws.com/mv/mvhousekeeping")
      .length === 2
  ) {
    return {
      status: true,
      data: {
        link: obj.base_64,
      },
    };
  }
  return axios
    .post(`${base_url}${api.getlink}`, { ...obj }, {})
    .then((response) => response.data);
  // .then((response) => {
  //   // if (response.error) {
  //   //   return callback({
  //   //     status: false,
  //   //   });
  //   // }
  //   return callback({
  //     status: true,
  //     link: response.data.link,
  //   });
  // });
};
