import React, { useEffect, useState } from "react";
import { addlist, edititem, month_short } from "../../API/api";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";

import dayjs from "dayjs";

export const EditiTems = ({
  current_selectedItem,
  setloader_acive,
  setcurrent_selectedItem,
  modal_active,
  modal_type,
  setmodal_type,
}) => {
  const [addItem_modal, setaddItem_modal] = useState(false);
  const [save_btn, setsave_btn] = useState(false);

  const [page_values, setpage_values] = useState({
    start_date: null,
    start_time: null,
    end_time: null,
    add: "",
    apt: "",
  });
  useEffect(() => {
    if (!current_selectedItem?.id) {
      return;
    }
    setpage_values({
      start_date: dayjs(JSON.parse(current_selectedItem.start).date),
      start_time: dayjs(
        new Date().toString().split(" ").slice(0, 4).join(" ") +
          " " +
          JSON.parse(current_selectedItem.start).time +
          ":00"
      ),
      end_time: dayjs(
        new Date().toString().split(" ").slice(0, 4).join(" ") +
          " " +
          JSON.parse(current_selectedItem.end).time +
          ":00"
      ),
      add: current_selectedItem.add,
      apt: current_selectedItem.apt,
    });
  }, [current_selectedItem]);

  useEffect(() => {
    if (
      page_values.start_date?.$d &&
      // page_values.end_date?.$d &&
      new Date(page_values.start_date?.$d) != "Invalid Date" &&
      // new Date(page_values.end_date?.$d) != "Invalid Date" &&
      page_values.start_time?.$d &&
      page_values.end_time?.$d &&
      new Date(page_values.start_time?.$d) != "Invalid Date" &&
      new Date(page_values.end_time?.$d) != "Invalid Date" &&
      page_values.add !== "" &&
      page_values.apt !== ""
    ) {
      // console.log(

      if (
        page_values.apt !== current_selectedItem.apt ||
        page_values.add !== current_selectedItem.add ||
        new Date(page_values.start_date)
          .toString()
          .split(" ")
          .slice(1, 4)
          .join("") !==
          new Date(JSON.parse(current_selectedItem.start).date)
            .toString()
            .split(" ")
            .slice(1, 4)
            .join("") ||
        new Date(page_values.start_time).toString().split(" ")[4] !==
          new Date(
            new Date().toString().split(" ").slice(0, 4).join(" ") +
              " " +
              JSON.parse(current_selectedItem.start).time +
              ":00"
          )
            .toString()
            .split(" ")[4] ||
        new Date(page_values.end_time).toString().split(" ")[4] !==
          new Date(
            new Date().toString().split(" ").slice(0, 4).join(" ") +
              " " +
              JSON.parse(current_selectedItem.end).time +
              ":00"
          )
            .toString()
            .split(" ")[4]
      ) {
        setsave_btn(true);
      } else {
        setsave_btn(false);
      }
    } else {
      setsave_btn(false);
    }
  }, [page_values]);

  const handel_pagevalues = (data) => {
    return setpage_values((prev) => ({ ...prev, ...data }));
  };
  const handelSaveItem = () => {
    if (!save_btn) {
      return;
    }
    let data_obj = {
      id: current_selectedItem.id,
      add: page_values.add,
      apt: page_values.apt,
      start: JSON.stringify({
        date:
          month_short[page_values.start_date.$M] +
          " " +
          ("00" + page_values.start_date.$D).slice(-2) +
          " " +
          page_values.start_date.$y,
        time:
          ("00" + page_values.start_time.$H).slice(-2) +
          ":" +
          ("00" + page_values.start_time.$m).slice(-2),
      }),
      end: JSON.stringify({
        date:
          month_short[page_values.start_date.$M] +
          " " +
          ("00" + page_values.start_date.$D).slice(-2) +
          " " +
          page_values.start_date.$y,
        time:
          ("00" + page_values.end_time.$H).slice(-2) +
          ":" +
          ("00" + page_values.end_time.$m).slice(-2),
      }),
    };
    edititem(
      {
        ...data_obj,
      },
      (res) => {
        if (!res.status) {
          alert("Network error Check connetion or try again");
          return;
        }
        return window.location.reload();
      }
    );
  };
  return (
    <>
      <div className="edit_items_modal" active={modal_active.toString()}>
        <div className="modal">
          <div className="modal_header">
            <div className="close"></div>
            <div className="txt">Edit Entry</div>
            <div className="close">
              <div></div>
              <div></div>
            </div>
          </div>
          <div>
            <TextField
              id="outlined-multiline-flexible"
              label="Address"
              multiline
              rowsMax="3"
              value={page_values.add}
              onChange={({ target }) => {
                handel_pagevalues({ add: target.value });
              }}
              margin="normal"
              variant="outlined"
              size="normal"
            />
          </div>
          <div>
            <TextField
              id="outlined-basic"
              label="Apartment"
              variant="outlined"
              value={page_values.apt}
              onChange={({ target }) => {
                handel_pagevalues({ apt: target.value });
              }}
            />
          </div>
          <div className="">
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of cleaning"
                  value={page_values.start_date}
                  onChange={(newValue) => {
                    handel_pagevalues({ start_date: newValue });
                  }}
                  format="MM/DD/YYYY"
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="time_group">
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Start Time"
                  value={page_values.start_time}
                  onChange={(newValue) =>
                    handel_pagevalues({ start_time: newValue })
                  }
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="End Time"
                  value={page_values.end_time}
                  onChange={(newValue) =>
                    handel_pagevalues({ end_time: newValue })
                  }
                />
              </LocalizationProvider>
            </div>
          </div>
          {/* <div className="time_group">
            <div className="title">End</div>
            <div>
              <div className="title">Date</div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={page_values.end_date}
                  onChange={(newValue) =>
                    handel_pagevalues({ end_date: newValue })
                  }
                  format="MMM DD, YYYY"
                />
              </LocalizationProvider>
            </div>
            <div>
              <div className="title">Time</div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  value={page_values.end_time}
                  onChange={(newValue) =>
                    handel_pagevalues({ end_time: newValue })
                  }
                />
              </LocalizationProvider>
            </div>
          </div> */}

          <div className="btn_group">
            <div
              className="btn cancel"
              onClick={() => {
                setmodal_type((prev) => (prev == "edittemp" ? "fulledit" : ""));
                setaddItem_modal(false);
                if (modal_type != "edittemp") {
                  setpage_values({
                    start_date: null,
                    start_time: null,
                    // end_date: null,
                    end_time: null,
                    add: "",
                    apt: "",
                  });
                }
              }}
            >
              Cancel
            </div>
            <div
              className="btn save"
              active={save_btn.toString()}
              onClick={handelSaveItem}
            >
              Save
            </div>
          </div>
        </div>
      </div>
      {/* <div className="add_plus" onClick={() => setaddItem_modal(true)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xlink="http://www.w3.org/1999/xlink"
          svgjs="http://svgjs.com/svgjs"
          width="20"
          height="20"
          x="0"
          y="0"
          viewBox="0 0 512 512"
          space="preserve"
          class=""
        >
          <g>
            <path
              d="M256 439.98c-13.81 0-25-11.19-25-25V97.02c0-13.81 11.19-25 25-25s25 11.19 25 25v317.96c0 13.81-11.19 25-25 25z"
              fill="var(--base)"
            ></path>
            <path
              d="M414.98 281H97.02c-13.81 0-25-11.19-25-25s11.19-25 25-25h317.96c13.81 0 25 11.19 25 25s-11.19 25-25 25z"
              fill="var(--base)"
            ></path>
          </g>
        </svg>

        <div>Add</div>
      </div> */}
    </>
  );
};
