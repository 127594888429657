import React from "react";

export const Loader = ({ show }) => {
  return (
    <div className="loader" active={show.toString()}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        // width="500.000000pt"
        // height="500.000000pt"
        viewBox="0 0 625.000000 625.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,625.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path d="M1120 3125 l0 -1035 140 0 140 0 0 753 c1 679 2 750 16 732 9 -11 179 -292 378 -625 l362 -605 69 -3 70 -2 105 177 c57 98 190 322 295 498 105 176 227 380 271 454 l79 135 3 -757 2 -757 140 0 140 0 0 757 c0 448 4 753 9 748 5 -6 68 -145 141 -310 73 -165 185 -419 249 -565 64 -146 153 -347 196 -447 l80 -182 148 -1 148 0 18 43 c10 23 50 114 89 202 101 232 273 624 359 820 41 94 113 258 160 365 47 107 93 213 103 235 79 176 170 388 170 396 0 6 -56 8 -142 7 l-143 -3 -215 -486 c-118 -268 -287 -649 -374 -848 -88 -198 -162 -361 -166 -361 -3 0 -21 35 -39 78 -18 42 -73 167 -121 277 -89 201 -225 513 -460 1050 l-127 290 -163 0 -164 0 -118 -200 c-65 -110 -130 -222 -146 -250 -15 -27 -131 -225 -257 -440 -126 -214 -251 -429 -279 -478 -39 -67 -53 -84 -62 -75 -6 7 -72 117 -146 243 -178 302 -463 783 -602 1015 l-111 185 -122 3 -123 3 0 -1036z" />
        </g>
      </svg>
    </div>
  );
};
