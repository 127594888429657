import { useEffect, useState } from "react";
import { Table } from "./Component/Table/Table";
import { AddiTems } from "./Component/AddItems/AddiTems";
import { ViewItems } from "./Component/ViewItems/ViewItems";
import {
  color_obj,
  edititem,
  edititemimages,
  edititemstatus,
  getlink,
  getlist,
  month_short,
} from "./API/api";

import "./style/main.css";
import { Loader } from "./Component/Loader/Loader";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { EditiTems } from "./Component/EditItems/EditiTems";

import add_img from "./images/add_img.png";

function App() {
  const [table, settable] = useState([]);
  const [loader_active, setloader_acive] = useState(false);
  const [viewItem_modal, setviewItem_modal] = useState(false);
  const [temp_current_selectedItem, settemp_current_selectedItem] = useState(
    {}
  );

  const handeltemp_edit = (data) => {
    return settemp_current_selectedItem((prev) => ({ ...prev, ...data }));
  };

  const [current_selectedItem, setcurrent_selectedItem] = useState({});
  const [modal_type, setmodal_type] = useState("");
  // console.log(table);
  // useEffect(() => {
  // console.log("here1");
  // if (current_selectedItem?.id) {
  // console.log("here");
  // try {
  // let [start, end] = [
  //   JSON.parse(current_selectedItem.start),
  //   JSON.parse(current_selectedItem.end),
  // ];
  // settemp_current_selectedItem({
  //   ...current_selectedItem,
  //   start_date: dayjs(new Date(start.date)),
  //   start_time: dayjs(
  //     new Date(
  //       new Date().toString().split(" ").slice(0, 4) +
  //         " " +
  //         start.time +
  //         ":00"
  //     )
  //   ),
  //   end_date: dayjs(new Date(end.date)),
  //   end_time: dayjs(
  //     new Date(
  //       new Date().toString().split(" ").slice(0, 4) +
  //         " " +
  //         end.time +
  //         ":00"
  //     )
  //   ),
  // });
  // } catch (err) {
  //   console.log(err);
  // }
  // }
  // }, [current_selectedItem]);
  // console.log(current_selectedItem, Object.keys(current_selectedItem).length);
  const handel_modaldata = (data) => {
    setcurrent_selectedItem({ ...data });
    let [start, end] = [JSON.parse(data.start), JSON.parse(data.end)];
    settemp_current_selectedItem({
      ...data,
      start_date: dayjs(new Date(start.date)),
      start_time: dayjs(
        new Date(
          new Date().toString().split(" ").slice(0, 4) +
            " " +
            start.time +
            ":00"
        )
      ),
      end_date: dayjs(new Date(end.date)),
      end_time: dayjs(
        new Date(
          new Date().toString().split(" ").slice(0, 4) + " " + end.time + ":00"
        )
      ),
    });
  };

  useEffect(() => {
    if (modal_type === "reload" || modal_type === "") {
      setloader_acive(true);
      getlist((data) => {
        setloader_acive(false);
        // console.log(data);
        if (!data.status) {
          alert("Network error");
          return;
        }
        settable([...data.data.items]);
      });
    }
  }, [modal_type]);
  // console.log(modal_type, ["fulledit", "edittemp"].includes(modal_type));

  // const [date, setdate] = useState(null);
  // const [date_show, setdate_show] = useState(true);

  // console.log(current_selectedItem);

  return (
    <>
      <Loader {...{ show: loader_active }} />
      <div className={"page_main"}>
        <div className="page_header">
          <div>
            <div className="txt">HouseKeeping</div>
            <label htmlFor="table_search" className="search">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xlink="http://www.w3.org/1999/xlink"
                svgjs="http://svgjs.com/svgjs"
                width="17"
                height="17"
                x="0"
                y="0"
                viewBox="0 0 118.783 118.783"
                style={{ "enable-background": "new 0 0 512 512" }}
                space="preserve"
                class=""
              >
                <g>
                  <path
                    d="M115.97 101.597 88.661 74.286a47.75 47.75 0 0 0 7.333-25.488c0-26.509-21.49-47.996-47.998-47.996S0 22.289 0 48.798c0 26.51 21.487 47.995 47.996 47.995a47.776 47.776 0 0 0 27.414-8.605l26.984 26.986a9.574 9.574 0 0 0 6.788 2.806 9.58 9.58 0 0 0 6.791-2.806 9.602 9.602 0 0 0-.003-13.577zM47.996 81.243c-17.917 0-32.443-14.525-32.443-32.443s14.526-32.444 32.443-32.444c17.918 0 32.443 14.526 32.443 32.444S65.914 81.243 47.996 81.243z"
                    fill="#000000"
                  ></path>
                </g>
              </svg>
              <input id="tablle_search" type="text" placeholder="Search" />
            </label>
            <AddiTems />
          </div>
        </div>
        <div
          className={
            "page_body" +
            (["fulledit", "edittemp"].includes(modal_type) ? " edit_mode" : "")
          }
        >
          <Table
            {...{
              table,
              setcurrent_selectedItem,
              current_selectedItem,
              setviewItem_modal,
              setmodal_type,
              handel_modaldata,
              setloader_acive,
            }}
          />
          <PageEdit
            {...{
              current_selectedItem,
              temp_current_selectedItem,
              settemp_current_selectedItem,
              handeltemp_edit,
              setloader_acive,
              setmodal_type,
              modal_active: ["fulledit", "edittemp"].includes(modal_type),
            }}
          />
        </div>
      </div>
      <EditiTems
        {...{
          modal_active: ["edit", "edittemp"].includes(modal_type),
          current_selectedItem,
          setloader_acive,
          setcurrent_selectedItem,
          modal_type,
          setmodal_type,
        }}
      />
    </>
  );
}

const PageEdit = ({
  current_selectedItem,
  temp_current_selectedItem,
  settemp_current_selectedItem,
  handeltemp_edit,
  setloader_acive,
  modal_active,
  setmodal_type,
}) => {
  console.log(current_selectedItem, temp_current_selectedItem);
  const [modal_short, setmodal_short] = useState("");

  if (!modal_active) {
    return <div className="page_edit"></div>;
  }
  let [start, end] = [
    JSON.parse(temp_current_selectedItem?.start),
    JSON.parse(temp_current_selectedItem?.end),
  ];
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      // return;
      [...e.target.files].map((el) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          settemp_current_selectedItem((prev) => ({
            ...prev,
            before_img: [...prev.before_img, reader.result?.toString() || ""],
          }));
        });
        reader.readAsDataURL(el);
      });
      e.target.value = null;
    }
  };
  const onSelectFile1 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      [...e.target.files].map((el) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          settemp_current_selectedItem((prev) => ({
            ...prev,
            after_img: [...prev.after_img, reader.result?.toString() || ""],
          }));
        });
        reader.readAsDataURL(el);
      });
      e.target.value = null;
    }
  };
  const handelSaveItem = () => {
    // if (!save_btn) {
    //   return;
    // }
    try {
      setloader_acive(true);
      let get_links = [
        ...temp_current_selectedItem.before_img.map((el) => {
          return getlink({
            base_64: el,
            id: temp_current_selectedItem.id,
          });
        }),
        "%%%julietteimages.s3.amazonaws.com/mv/mvhousekeeping%%%",

        ...temp_current_selectedItem.after_img.map((el) => {
          return getlink({
            base_64: el,
            id: temp_current_selectedItem.id,
          });
        }),
      ];
      Promise.all(get_links).then((values) => {
        let divider_index = values.indexOf(
          "%%%julietteimages.s3.amazonaws.com/mv/mvhousekeeping%%%"
        );
        let image_links_before = [
          ...values.slice(0, divider_index).map((el) => el.data.link),
        ];
        let image_links_after = [
          ...values.slice(divider_index + 1).map((el) => el.data.link),
        ];
        console.log({
          id: temp_current_selectedItem.id,
          before: image_links_before,
          after: image_links_after,
        });
        // return;
        edititemimages(
          {
            id: temp_current_selectedItem.id,
            before: image_links_before,
            after: image_links_after,
          },
          (res) => {
            setloader_acive(false);
            if (!res.status) {
              return alert("Network error Check connetion or try again");
            }
            // return window.location.reload();
          }
        );
      });
    } catch (err) {
      setloader_acive(false);
      return alert("Network error Check connetion or try again");
    }
  };
  return (
    <div className="page_edit">
      <div className="breadcrumb">
        <div onClick={() => setmodal_type("reload")} className="main_link">
          Housekeeping
        </div>
        <div>{">"}</div>
        <div className="add_txt">
          <div>{temp_current_selectedItem.add}</div>
          {/* <div>{temp_current_selectedItem.apt}</div> */}
        </div>
      </div>
      <div className="card1">
        <div className="card_title">Order Details</div>
        <div className="address">
          <div>
            <div>Address</div>
            <div>{temp_current_selectedItem.add}</div>
          </div>
          <div>
            <div>Apt</div>
            <div>{temp_current_selectedItem.apt}</div>
          </div>
          <div>
            <div>Date and Time</div>
            <div>
              {[JSON.parse(temp_current_selectedItem.start).date].reduce(
                (prev, curr) => {
                  let temp_arr = curr.split(" ");
                  return `${temp_arr[0]} ${temp_arr[1]}, ${temp_arr[2]}`;
                },
                ""
              ) +
                " " +
                JSON.parse(temp_current_selectedItem.start).time +
                " | " +
                JSON.parse(temp_current_selectedItem.end).time}
            </div>
          </div>
        </div>
        <div className="btn" onClick={() => setmodal_type("edittemp")}>
          Edit Order Details
        </div>
        <div className="card_title status_title">Status:</div>
        <div className="status_chips">
          {["New", "In Progress", "Completed", "Cancel"].map((el) => (
            <div
              className={
                temp_current_selectedItem.status ===
                el.split(" ").join("").toLowerCase()
                  ? " active"
                  : ""
              }
              style={{
                "--chip_color": color_obj[el.split(" ").join("").toLowerCase()],
              }}
              onClick={() => {
                if (
                  temp_current_selectedItem.status ===
                  el.split(" ").join("").toLowerCase()
                ) {
                  return;
                }
                handeltemp_edit({
                  status: el.split(" ").join("").toLowerCase(),
                });
                edititemstatus(
                  {
                    id: temp_current_selectedItem.id,
                    status: el.split(" ").join("").toLowerCase(),
                  },
                  (res) => {
                    setloader_acive(false);
                    if (!res.status) {
                      return alert(
                        "Network error Check connetion or try again"
                      );
                    }

                    return;
                    // window.location.reload();
                  }
                );
              }}
            >
              {el}
            </div>
          ))}
        </div>
      </div>
      <div className="card2">
        <div className="card_title">Before Photos:</div>
        <div className="card_group">
          {temp_current_selectedItem.before_img.map((el, i) => {
            return (
              <div className="items">
                {el.split("julietteimages.s3.amazonaws.com/mv/mvhousekeeping")
                  .length === 2 ? (
                  <>
                    {el.match(".mp4") || el.match(".mp3") ? (
                      <video
                        src={el}
                        controls
                        controlsList="nofullscreen nodownload noremoteplayback"
                      />
                    ) : (
                      <img src={el} alt="img" />
                    )}
                  </>
                ) : (
                  <>
                    {el.split("image").length === 2 ? (
                      <img src={el} alt="img" />
                    ) : (
                      <video
                        src={el}
                        controls
                        controlsList="nofullscreen nodownload noremoteplayback"
                      />
                    )}
                  </>
                )}
                <div
                  className="delete"
                  onClick={() => {
                    let temp_arr = [
                      ...temp_current_selectedItem.before_img,
                    ].filter((el1, i1) => i1 !== i);

                    settemp_current_selectedItem((prev) => ({
                      ...prev,
                      before_img: [...temp_arr],
                    }));
                  }}
                >
                  <div></div>
                </div>
              </div>
            );
          })}
          <label className="add">
            <img src={add_img} />
            <input
              type={"file"}
              id="before_inp"
              accept=".mp3,.mp4,.jpeg,.png,.jpg"
              onChange={onSelectFile}
              multiple
            />
          </label>
        </div>
        {(temp_current_selectedItem.before_img
          .reduce((prev, curr) => {
            return [
              prev,
              !(
                curr.split("julietteimages.s3.amazonaws.com/mv/mvhousekeeping")
                  .length == 2
              ),
            ];
          }, [])
          ?.includes(true) ||
          temp_current_selectedItem.before_img.length !==
            current_selectedItem.before_img.length) && (
          <div className="btn" onClick={handelSaveItem}>
            Save new Images
          </div>
        )}
      </div>
      <div className="card3">
        <div className="card_title">After Photos:</div>
        <div className="card_group">
          {temp_current_selectedItem.after_img.map((el, i) => {
            return (
              <div className="items">
                {el.split("julietteimages.s3.amazonaws.com/mv/mvhousekeeping")
                  .length === 2 ? (
                  <>
                    {el.match(".mp4") || el.match(".mp3") ? (
                      <video
                        src={el}
                        controls
                        controlsList="nofullscreen nodownload noremoteplayback"
                      />
                    ) : (
                      <img src={el} alt="img" />
                    )}
                  </>
                ) : (
                  <>
                    {el.split("image").length === 2 ? (
                      <img src={el} alt="img" />
                    ) : (
                      <video
                        src={el}
                        controls
                        controlsList="nofullscreen nodownload noremoteplayback"
                      />
                    )}
                  </>
                )}
                <div
                  className="delete"
                  onClick={() => {
                    let temp_arr = [
                      ...temp_current_selectedItem.after_img,
                    ].filter((el1, i1) => i1 !== i);

                    settemp_current_selectedItem((prev) => ({
                      ...prev,
                      after_img: [...temp_arr],
                    }));
                  }}
                >
                  <div></div>
                </div>
              </div>
            );
          })}
          <label className="add">
            <img src={add_img} />
            <input
              type={"file"}
              id="before_inp"
              accept=".mp3,.mp4,.jpeg,.png,.jpg"
              onChange={onSelectFile1}
              multiple
            />
          </label>
        </div>
        {(temp_current_selectedItem.after_img
          .reduce((prev, curr) => {
            return [
              prev,
              !(
                curr.split("julietteimages.s3.amazonaws.com/mv/mvhousekeeping")
                  .length == 2
              ),
            ];
          }, [])
          ?.includes(true) ||
          temp_current_selectedItem.after_img.length !==
            current_selectedItem.after_img.length) && (
          <div className="btn" onClick={handelSaveItem}>
            Save new Images
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
